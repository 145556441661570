/**
 * Represents a collection of border radius values for consistent styling.
 */
export const borderRadius = {
    /** @value "2.0rem" */
    card: "2.0rem",

    /** @value "3.0rem" */
    card2: "3.0rem",

    /** @value "1.5rem" */
    largeModal: "1.5rem",

    /** @value "4.0rem" */
    circular: "4.0rem",

    /** @value "2.0rem" */
    smallModal: "2.0rem",

    /** @value "1.5rem" */
    longInput: "1.5rem",

    /** @value "5.0rem" */
    shortInput: "5.0rem",
};
