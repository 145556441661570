import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";
import useCombinedState from "@/utils/hooks/useCombinedState";
import { useIsomorphicLayoutEffect } from "react-use";
import { HTML_FONT_SIZE } from "@/utils/theme";

export const useBreakPoints = ({
    xs = 0,
    sm = 600,
    md = 900,
    lg = 1200,
    xl = 1535,
}: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
} = {}) => {
    const theme = createTheme({
        typography: {
            // Tell Material-UI what's the font-size on the html element is.
            htmlFontSize: HTML_FONT_SIZE,
        },
        breakpoints: { values: { xs, sm, md, lg, xl } },
    });

    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
    const xsUp = useMediaQuery(theme.breakpoints.up("xs"));

    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));

    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const mdUp = useMediaQuery(theme.breakpoints.up("md"));

    const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
    const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

    const xlDown = useMediaQuery(theme.breakpoints.down("xl"));
    const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

    const currentBreakpoint = xsDown ? "xxs" : smDown ? "xs" : mdDown ? "sm" : lgDown ? "md" : xlDown ? "lg" : "xl";

    return { currentBreakpoint, xsDown, xsUp, smDown, smUp, mdDown, mdUp, lgDown, lgUp, xlDown, xlUp };
};

//breakpoints tailored for container size, useful for components that are not full width of the page
//default container is document.body
export const useContainerBreakPoints = (
    {
        xs = 0,
        sm = 600,
        md = 900,
        lg = 1200,
        xl = 1535,
        container,
    }: {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
        xl?: number;
        container?: Element;
    } = {
        container: document.body,
    }
) => {
    const [{ xsDown, xsUp, smDown, smUp, mdDown, mdUp, lgDown, lgUp, xlDown, xlUp }, setBreakpoints] = useCombinedState(
        {
            xsDown: false,
            xsUp: false,
            smDown: false,
            smUp: false,
            mdDown: false,
            mdUp: false,
            lgDown: false,
            lgUp: false,
            xlDown: false,
            xlUp: false,
        }
    );

    useIsomorphicLayoutEffect(() => {
        if (!container) return;

        const assignBreakPoints = () => {
            const width = container.clientWidth;

            setBreakpoints({
                xsDown: width < xs,
                xsUp: width >= xs,
                smDown: width < sm,
                smUp: width >= sm,
                mdDown: width < md,
                mdUp: width >= md,
                lgDown: width < lg,
                lgUp: width >= lg,
                xlDown: width < xl,
                xlUp: width >= xl,
            });
        };

        //assign on mount
        assignBreakPoints();

        const resizeObserver = new ResizeObserver(assignBreakPoints);
        resizeObserver.observe(container);

        return () => resizeObserver.unobserve(container);
    }, [container, lg, md, setBreakpoints, sm, xl, xs]);

    return { xsDown, xsUp, smDown, smUp, mdDown, mdUp, lgDown, lgUp, xlDown, xlUp };
};
