import ClickAwayListener from "@mui/material/ClickAwayListener";
import Slide, { SlideProps } from "@mui/material/Slide";
import { FlexColumn } from "@/components/Flex";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { themeColors } from "@/utils/themeColors";

export type SlideMenuProps = {
    style?: React.CSSProperties;
    isOpen: boolean;
    onClose: (e?: React.MouseEvent) => void;
    onClickAway?: () => void;
    children: React.ReactNode;
} & Omit<SlideProps, "open" | "children">;

/**
 * A menu that slides from bottom to top by default. Mostly used for mobile's popup
 */
const SlideMenu = ({ children, style = {}, isOpen, onClose, onClickAway, ...MuiSlideMenuProps }: SlideMenuProps) => {
    const { smDown } = useBreakPoints();

    const handleClickAway = (event: MouseEvent | TouchEvent) => {
        if (event.type !== "touchend" && smDown) return;

        if (typeof onClickAway === "function") {
            return onClickAway();
        }

        onClose();
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Slide
                {...MuiSlideMenuProps}
                in={isOpen}
                direction={MuiSlideMenuProps?.direction || "up"}
                mountOnEnter
                unmountOnExit>
                <FlexColumn
                    style={{
                        position: "fixed",
                        backgroundColor: themeColors.neutralWhite,
                        overscrollBehavior: "none",
                        overflowY: "auto",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        left: 0,
                        zIndex: 999_995,
                        ...style,
                    }}>
                    {children}
                </FlexColumn>
            </Slide>
        </ClickAwayListener>
    );
};

export default SlideMenu;
