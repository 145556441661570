import { themeColors } from "@/utils/themeColors";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

export type ConditionalTooltipProps = {
    tooltip?: React.ReactNode | string;
} & Omit<TooltipProps, "title">;

const ConditionalTooltip = ({
    children,
    tooltip,
    placement = "top",
    slotProps,
    ...TooltipProps
}: ConditionalTooltipProps) => {
    if (!tooltip) {
        return <>{children}</>;
    }

    return (
        <Tooltip
            arrow
            title={tooltip}
            placement={placement}
            leaveTouchDelay={500}
            slotProps={{
                arrow: {
                    sx: {
                        color: themeColors.neutralBlack,
                        ...slotProps?.arrow?.sx,
                    },
                },
                tooltip: {
                    sx: {
                        color: themeColors.neutralWhite,
                        backgroundColor: themeColors.neutralBlack,
                        padding: "0.8rem 1rem",
                        fontSize: "1.3rem",
                        ...slotProps?.tooltip?.sx,
                    },
                },
            }}
            {...TooltipProps}>
            {children}
        </Tooltip>
    );
};

export default ConditionalTooltip;
