import { LucideProps, X } from "lucide-react";
import CircularButton, { CircularButtonProps } from "@/components/CircularButton";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import { useResponsiveDialogContextSelector } from "../hooks/useResponsiveDialogContext";
import { useClickLoading } from "@/hooks/useClickLoading";

type IconProps = { iconProps?: LucideProps };
type ResponsiveDialogStyles = {
    style?: React.CSSProperties;
    /** styles applied only when `Dialog` is active and take precedence over `style` props */
    forDialogStyle?: React.CSSProperties;
    /** styles applied only when `SlideMenu` is active and take precedence over `style` props */
    forSlideMenuStyle?: React.CSSProperties;
};
type ButtonProps = Omit<CircularButtonProps, "children" | "sx">;
type RequiredOnClick = { onClick: (e: React.MouseEvent) => void };

type DialogCloseBtnProps = IconProps & ResponsiveDialogStyles & ButtonProps & RequiredOnClick;

const DialogCloseBtn = ({
    iconProps: _iconProps = {},
    style: _style,
    forDialogStyle,
    forSlideMenuStyle,
    onClick,
    ...props
}: DialogCloseBtnProps) => {
    const isMobile = useResponsiveDialogContextSelector(state => state.isMobile);
    const { handleClick, isLoading } = useClickLoading({ onClick });

    // default icon styles
    const { style: iconStyle, ...iconProps } = { size: iconSizes.MD, strokeWidth: strokeWidth.normal, ..._iconProps };

    const style = {
        ..._style,
        ...(isMobile ? forSlideMenuStyle : forDialogStyle),
    };

    return (
        <CircularButton
            radius={"4rem"}
            disabled={isLoading}
            sx={{
                backgroundColor: "transparent",
                borderColor: themeColors.neutral1,
                borderWidth: "1px",
                borderStyle: "solid",
                pointerEvents: isLoading ? "none" : "all",
                justifyContent: "center",
                // icon styles
                "& > svg": { ...iconStyle },
                // override any style on hovered
                "&:hover": {
                    borderColor: themeColors.neutralBlack,
                    outline: "none",
                },
                ...style,
            }}
            onClick={handleClick}
            {...props}>
            <X {...iconProps} />
        </CircularButton>
    );
};

export default DialogCloseBtn;
