"use client";

import React, { useCallback, useState } from "react";

/**
 * @returns a normal `onClick` handler and a loading state
 */
export const useClickLoading = <T extends React.MouseEvent | React.FormEvent<HTMLFormElement> = React.MouseEvent>({
    onClick,
}: {
    onClick: (e?: T) => Promise<void> | void;
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = useCallback(
        async (e?: T) => {
            if (isLoading) return;

            setIsLoading(true);
            await onClick(e);
            setIsLoading(false);
        },
        [onClick, isLoading]
    );

    return { handleClick, isLoading };
};
