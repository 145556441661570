"use client";

import { useRef } from "react";
import dynamic from "next/dynamic";

const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

const ClickPlayLottie = (props: any) => {
    const lottieRef = useRef<typeof Lottie | undefined>(undefined);

    return <Lottie {...props} lottieRef={lottieRef} onClick={() => lottieRef.current?.goToAndPlay(0)} />;
};

export default ClickPlayLottie;
