"use client";

import React from "react";
import { themeColors } from "@/utils/themeColors";
import { FlexRowAlignCenter } from "./Flex";

// temporary fix for l-helix and l-pulsar not being recognized as valid components from the normal `import type {} from "ldrs";`
declare module "react" {
    namespace JSX {
        interface IntrinsicElements {
            "l-helix": {
                size?: string | number;
                color?: string | number;
                speed?: string | number;
            };
            "l-pulsar": {
                size?: string | number;
                color?: string | number;
                speed?: string | number;
            };
        }
    }
}

export type CenteredSpinnerProps = {
    className?: string;
    style?: React.CSSProperties;
    label?: string;
    size?: number;
    color?: string;
    Component?: "l-helix" | "l-pulsar";
};

const LoadingAnimation = ({ className, style, size = 40, color, Component = "l-helix" }: CenteredSpinnerProps) => {
    return (
        <FlexRowAlignCenter
            className={className}
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                color: color ?? themeColors.neutralBlack,
                ...style,
            }}>
            <Component
                speed={1.75}
                size={size}
                color={color ?? themeColors.neutralBlack}
                style={{ transform: "rotate(90deg)" }}
            />
        </FlexRowAlignCenter>
    );
};

export default LoadingAnimation;
