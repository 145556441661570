import { createContext, useContextSelector } from "@/utils/use-context-selector";

const ResponsiveDialogContext = createContext({ isMobile: false });

/**
 * Provider for `ResponsiveDialog` to let it's children knows that `isMobile` is truthy
 */
export const ResponsiveDialogContextProvider = ({
    isMobile,
    children,
}: {
    isMobile: boolean;
    children: React.ReactNode;
}) => {
    return <ResponsiveDialogContext.Provider value={{ isMobile }}>{children}</ResponsiveDialogContext.Provider>;
};

export const useResponsiveDialogContextSelector = <T,>(selector: (state: { isMobile: boolean }) => T) =>
    useContextSelector(ResponsiveDialogContext, selector);
