import { HTML_FONT_SIZE } from "./theme";

export const isElementInViewOfViewport = (el: Element) => {
    const bounding = el.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

/**
 * Scroll the window/canvas/div in view to the given id
 * @param id - id of the element/container to be scrolled
 * @param delay - delay is a must just in case the div element has animation which caused not direct visible
 * @param options - window.scrollTo options
 * @param offset - scroll top offset
 */
export const scrollToId = ({
    id,
    delay = 500,
    options = {},
    offset = 0,
}: {
    id: string;
    delay?: number;
    options?: object;
    offset?: number;
}) => {
    if (typeof window === "undefined") return;

    const element = document.getElementById(id);
    if (!element) return;

    const bodyTop = document.body.getBoundingClientRect().top;
    const elementTop = element.getBoundingClientRect().top;
    const elementPosition = elementTop - bodyTop;
    const offsetPosition = elementPosition - offset;

    setTimeout(() => {
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
            ...options,
        });
    }, delay);
};

export const preventClickedAreaToLostInputFocus = (event: PointerEvent, disableLostFocusAreaEl: Element) => {
    if (!disableLostFocusAreaEl) {
        return;
    }

    const isClickWithinDisabledArea = disableLostFocusAreaEl.contains(event.target);
    if (isClickWithinDisabledArea) {
        event.preventDefault();
    }
};

export const insertAtCurrentCursor = (newText, el = document.activeElement) => {
    if (!el) return undefined;

    // regular input elems
    const inputs = ["input", "select", "textarea"];
    if (inputs.includes(el?.tagName?.toLowerCase())) {
        const inputEl = el as HTMLInputElement | HTMLTextAreaElement;
        const [start, end] = [inputEl.selectionStart, inputEl.selectionEnd];
        inputEl.setRangeText(newText, start, end, "end");
        return inputEl.value;
    }

    return undefined;
};

export const preventStopPropogation = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
};

export const remToPx = (rem: string) => {
    const isSSR = typeof window === "undefined";
    const pxValue = isSSR
        ? HTML_FONT_SIZE
        : Number.parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    return Number.parseFloat(rem) * pxValue;
};

/**
 * Scroll into view but with a given offset to the top of the element
 */
export const scrollOffsetIntoView = ({
    offset,
    el,
    options,
}: {
    offset: number;
    el: Element;
    options?: ScrollToOptions;
}) => {
    const elementPosition = el.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;
    window.scrollTo({ top: offsetPosition, behavior: "smooth", ...options });
};

export const waitForElement = async (id: string, maxTries = 10) => {
    let element = document.getElementById(id);

    for (let i = 0; i < maxTries; i++) {
        await new Promise(resolve => setTimeout(resolve, 100));
        element = document.getElementById(id);

        if (element) {
            return element;
        }
    }

    return null;
};
