import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import WithOptionsMenu from "@/components/WithOptionsMenu";
import { strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import noop from "@knowt/syncing/utils/noop";
import { SxProps } from "@mui/material/styles";
import { ChevronDown } from "lucide-react";
import React from "react";

export type SelectProps<T extends { label: string; value: unknown; node?: React.ReactNode }> = {
    options: T[];
    selected: T;
    id?: string;
    btnSx?: SxProps;
    menuSx?: SxProps;
    onChange: (e: T) => void;
    disabled?: boolean;
    /** In case you want to customize the label. By default it will just display a plain text of `option.label` */
    renderLabel?: (props: T) => React.ReactNode;
    //** In case you want to precise the empty state displayed label */
    emptyStateLabel?: string;
    /** In case you want to put icon besides the label. By default it's a chevron. Pass `null` to remove the icon */
    adornment?: React.ReactNode;
    /** Position of the menu animation from what point to which direction it will be opened to */
    transitionOrigin?: {
        anchorOrigin: { vertical: "bottom" | "top" | "center"; horizontal: "right" | "left" | "center" };
        transformOrigin: { vertical: "bottom" | "top" | "center"; horizontal: "right" | "left" | "center" };
    };
    adornmentProps?: React.SVGProps<SVGSVGElement>;
};

const Select = <T extends { label: string; value: unknown; node?: React.ReactNode }>({
    id,
    options,
    btnSx,
    menuSx,
    transitionOrigin = {
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        transformOrigin: { vertical: "top", horizontal: "center" },
    },
    onChange,
    disabled,
    selected,
    emptyStateLabel = "No options available",
    adornmentProps = {},
    adornment = (
        <ChevronDown
            size={"1.8rem"}
            strokeWidth={strokeWidth.normal}
            {...adornmentProps}
            style={{ marginLeft: "auto", ...adornmentProps?.style }}
        />
    ),
    renderLabel = ({ label }) => label,
}: SelectProps<T>) => {
    const isNoOptionsAvailable = !options || options.length === 0;

    return (
        <WithOptionsMenu
            menuProps={{
                PaperProps: {
                    sx: {
                        borderRadius: "1.5rem",
                        width: "fit-content",
                        maxWidth: "20rem",
                        marginTop: "1rem",
                        ...menuSx,
                    },
                    className: "scrollbar",
                },
                anchorOrigin: transitionOrigin.anchorOrigin,
                transformOrigin: transitionOrigin.transformOrigin,
                BackdropProps: { style: { zIndex: 0 } },
            }}
            options={
                !isNoOptionsAvailable
                    ? options.map(o => ({ label: o.label, onClick: () => onChange(o), node: o.node }))
                    : [{ label: emptyStateLabel, onClick: noop, disabled: true }]
            }>
            {({ openMenu }) => {
                return (
                    <CircularRectTextButton
                        id={id}
                        onClick={e => (!disabled ? openMenu(e) : noop)}
                        type="button"
                        sx={{
                            borderRadius: "unset",
                            backgroundColor: "transparent",
                            padding: "1rem 1.5rem",
                            gap: "0.5rem",
                            "&:hover, &:active": {
                                // boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.08)",
                                outline: `1px solid ${themeColors.neutralBlack}`,
                            },
                            ...btnSx,
                        }}
                        tooltip={disabled ? "Locked" : "Select an option"}>
                        <p className="ellipsisText" style={{ margin: 0, maxWidth: adornment ? "90%" : "100%" }}>
                            {renderLabel(selected)}
                        </p>
                        {adornment}
                    </CircularRectTextButton>
                );
            }}
        </WithOptionsMenu>
    );
};

export default Select;
