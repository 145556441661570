import MUIDialog, { DialogProps } from "@mui/material/Dialog";

/**
 * Wrapper around MUI Dialog component, with some default Knowt styling applied, like backdrop opacity and no shadow by default.
 * This component removes MUI Dialog's opinionated body styles.
 */
export default function Dialog({ children, slotProps, ...rest }: { children: React.ReactNode } & DialogProps) {
    return (
        <MUIDialog
            {...rest}
            disableScrollLock
            slotProps={{
                ...slotProps,
                paper: {
                    ...slotProps?.paper,
                    style: {
                        color: "inherit",
                        boxShadow: "none",
                        borderRadius: "0",
                        maxWidth: "unset",
                        width: "fit-content",
                        overscrollBehavior: "none",
                        ...slotProps?.paper?.style,
                    },
                },
                backdrop: {
                    ...slotProps?.backdrop,
                    style: {
                        backdropFilter: "blur(3px) brightness(0.4)",
                        WebkitBackdropFilter: "blur(3px) brightness(0.4)",
                        ...slotProps?.backdrop?.style,
                    },
                },
                root: {
                    ...slotProps?.root,
                    style: {
                        zIndex: 1300,
                        ...slotProps?.root?.style,
                    },
                },
                transition: {
                    timeout: {
                        appear: 50,
                        enter: 50,
                        exit: 150,
                    },
                    unmountOnExit: true,
                    mountOnEnter: true,
                    ...slotProps?.transition,
                },
            }}>
            {children}
        </MUIDialog>
    );
}
