import { FlexRowAlignCenter } from "@/components/Flex";

export type CircularProps = {
    radius?: string;
} & React.HTMLAttributes<HTMLSpanElement>;

/**
 * Nothing special, just a circular background
 */
const Circular = ({
    ref,
    children,
    style,
    radius,
    ...spanProps
}: CircularProps & {
    ref?: React.RefObject<unknown>;
}) => {
    return (
        <FlexRowAlignCenter
            ref={ref}
            as={"span"}
            className="bold"
            style={{
                fontFamily: "var(--knowt-font-name)",
                justifyContent: "center",
                borderRadius: 9999,
                height: radius,
                width: radius,
                flexGrow: 0,
                flexShrink: 0,
                ...style,
            }}
            {...spanProps}>
            {children}
        </FlexRowAlignCenter>
    );
};

Circular.displayName = "Circular";

export default Circular;
