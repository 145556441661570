/**
 * Represents a collection of spacing values for consistent layout and design.
 */
export const spacing = {
    NONE: "0rem",

    XXS: "0.4rem",

    XS: "0.8rem",

    XS_2: "1.2rem",

    SM: "1.6rem",

    MD_S: "2rem",

    MD: "2.4rem",

    LG_S: "2.8rem",

    LG: "3.2rem",

    LG_2: "3.6rem",

    LG_3: "4rem",

    XL: "5.0rem",
} as const;
