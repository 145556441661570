import Box, { BoxProps } from "@mui/material/Box";
import { themeColors } from "@/utils/themeColors";

// NOTE: should be used as a button wrapper, not as anchor tag.
const ClickableText = ({ children, style, ...buttonProps }: BoxProps) => {
    return (
        <Box
            component={"div"}
            {...buttonProps}
            sx={{
                fontFamily: "var(--knowt-font-name)",
                cursor: "pointer",
                fontWeight: "bold",
                background: "none",
                border: "none",
                padding: 0,
                outline: "inherit",
                color: themeColors.neutralBlack,
                "&:hover": {
                    color: themeColors.primary,
                    "& > *": {
                        color: themeColors.primary,
                    },
                },
                ...style,
                ...buttonProps.sx,
            }}>
            {children}
        </Box>
    );
};

export default ClickableText;
