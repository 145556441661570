import { ClassRole, FlashcardSet, Folder, UserDetails } from "@/graphql/schema";
import { AnyUserContent } from "@/types/common";
import { intersection } from "lodash-es";
import { isFolder } from "./dataCleaning";

export const filterClassItems = <T extends AnyUserContent>({
    user,
    classId,
    items,
    inTrash,
    sectionId,
}: {
    user: UserDetails;
    classId: string;
    items: Record<string, T>;
    inTrash?: boolean;
    sectionId?: string | null | undefined;
}) => {
    const isOwner = user?.classes?.teacher?.includes(classId);
    const memberEntry = user?.classes?.enrolled?.find(course => course.classId === classId);

    const isTeacher =
        memberEntry?.role === ClassRole.CONTRIBUTOR ||
        memberEntry?.role === ClassRole.VIEWER ||
        memberEntry?.role === ClassRole.CO_OWNER;

    const userSections = memberEntry?.sections || [];

    const canListFiles = isOwner || isTeacher;

    return Object.entries(items).filter(([, item]) => {
        return (
            !!item.trash === inTrash &&
            // make sure it's a member of the class, this can change from revalidations when moving files
            item.classId === classId &&
            // only show items that are not in a folder or are the root folders
            (!item.folderId || (isFolder(item) && !(item as Folder).parentId)) &&
            // only show draft flashcard sets to the owner
            (!(item as FlashcardSet).draft || item.userId === user?.ID) &&
            // 4 cases where an item is accessible:
            // Owner:
            // 1. the user is the owner, and there is no specified section
            // 2. the user is the owner, and the item is part of the specified section (or part of all)
            // Enrolled:
            // 3. the item is accessible to all sections (no sections specified)
            // 4. the user is enrolled in a section that the item is accessible to
            ((canListFiles && !sectionId) ||
                (canListFiles
                    ? !item.sections || item.sections.includes(sectionId)
                    : // for students, check if their section matches the item's sharing rules
                      !item.sections || intersection(item.sections, userSections).length > 0))
        );
    });
};
